<template>
  <div id="depart">
    <!-- 内容部分 -->
    <div style="" class="join_Project">
      <div class="authenticat_box">
        <div class="authenticat_right" style="">
          <ul class="authen_nav">
            <li
              :class="authenType == 1 ? 'authenactive' : ''"
              @click="changeauthenType(1)"
            >
              项目待签署
            </li>
            <li
              :class="authenType == 2 ? 'authenactive' : ''"
              @click="changeauthenType(2)"
            >
              阶段性结算
            </li>
          </ul>
        </div>
        <p style="clear: both"></p>
      </div>

      <div class="joinPro_con" style="margin-top: 20px" v-if="authenType == 1">
        <el-form
          :inline="true"
          :model="queryLimit"
          class="demo-form-inline"
          style="text-align: left"
        >
          <el-form-item label="" style="width: 260px">
            <div style="margin-left: 20px; margin-top: 20px">
              <el-input
                v-model="queryLimit.searchText"
                placeholder="根据项目编号查询"
                suffix-icon="el-icon-search"
                @input="changeState()"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>
        <div class="joinPro_tab" v-if="fileListlen > 0">
          <template>
            <el-table
              border
              :data="fileList"
              style="width: 1120px"
              class="center-table"
            >
              <el-table-column
                prop="projectName"
                label="项目"
                width="360"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="workPrice" label="价格" align="center">
              </el-table-column>
              <el-table-column
                prop="workHours"
                label="预计工时"
                width=""
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="expectDateStr"
                label="交付时间"
                width=""
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="noticeDateStr"
                label="通知签署时间"
                align="center"
              >
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.state == 2"
                    class="pointer down"
                    style="margin-right: 10px"
                    @click="viewprohandle(scope.row)"
                    >签署</span
                  >
                  <span
                    v-else
                    class="pointer down"
                    style="margin-right: 10px"
                    @click="viewPdf(scope.row)"
                    >查看</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <div class="pagingbox" style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryLimit.page"
              :page-sizes="pageSizes"
              :page-size="queryLimit.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>

        <div v-else class="history_nodata">
          <p style="text-align: center; padding-top: 300px">
            <img src="../../assets/images/nodata/file_nodata.png" alt="" />
          </p>
          <p style="text-align: center; font-size: 14px">暂无文件</p>
        </div>
      </div>

      <div class="joinPro_con" style="margin-top: 20px" v-else>
        <div class="settletxtbox">
          <div class="settletxtbg"></div>
          <div class="settle_txt">
            <p>
              本页面项目由于特殊原因需等待补充资料，您可以电请结算，通过后提交项目按照以下节点作为判定点，按照百分比结算。
            </p>
            <p>
              杆件布置完成(具备项目工程量体量)，节点布置完成(具体图纸全面审查)，模型碰撞校核编号完成(具备模型交付)。对应
              支付标准:15%、70%、15%。其中节点布置完成可分为名类型节点试做、未发现问题节点布置完成、节点布置完成比例再次细分。
              对应70%比例的百分比
            </p>
          </div>
        </div>

        <div class="joinPro_tab" v-if="settleCount > 0">
          <template>
            <el-table
              border
              :data="settleList"
              style="width: 1120px"
              class="center-table"
            >
              <el-table-column
                prop="projectCode"
                label="项目"
                width="360"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="crtDateStr"
                label="通知时间"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="typeStr"
                label="类型"
                width=""
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="des"
                label="备注说明"
                width=""
                align="center"
              >
              </el-table-column>
              <el-table-column prop="stateStr" label="状态" align="center">
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.state==0"
                    class="pointer down"
                    style="margin-right: 10px"
                    @click="changeSettlestate(scope.row, 1)"
                    >等待</span
                  >
                  <span
                    v-if="scope.row.state==0"
                    class="pointer down"
                    style="margin-right: 10px"
                    @click="changeSettlestate(scope.row, 2)"
                    >申请提前结算</span
                  >
                  <span
                    v-if="scope.row.state==3"
                    class="pointer down"
                    style="margin-right: 10px"
                    @click="viewSettle(scope.row)"
                    >查看</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <div class="pagingbox" style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="SettleLimit.page"
              :page-sizes="pageSizes"
              :page-size="SettleLimit.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="settleCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <el-dialog
        custom-class="isHandleDialog"
        :visible.sync="dialogproHandle"
        width="548px"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div>
          <div ref="pdfDom" id="pdf-details" class="pdf-details">
            <p class="dialogHandle_head">委托协议</p>
            <div class="clientcon">
              <p>甲方：山西众之翼建筑科技有限公司</p>
              <p>乙方：{{ currentdata.userName }}</p>
            </div>

            <div class="proHandleinfor">
              <div class="handleinfor_con">
                <p>项目编号：{{ currentdata.projectCode }}</p>
                <p>结构类型：{{ currentdata.realmName }}</p>
                <p>价格：{{ currentdata.workPrice }}元</p>
                <p style="clear: both"></p>
              </div>
              <div class="handleinfor_require">
                <p class="handle_requiretit">项目要求：</p>
                <div class="handle_requirelist">
                  <p
                    v-for="(item, index) in currentdata.requireList"
                    :key="index"
                  >
                    {{ index + 1 }}.{{ item }}
                  </p>
                </div>
                <p style="clear: both"></p>
              </div>
              <p style="clear: both"></p>
            </div>

            <div class="agreementCon" ref="agreementCon">
              <p style="text-align: center; font-weight: bold">特别提示</p>
              <p>
                为了维护您的合法权益，请在签署本合同前，仔细阅读本合同的所有条款并特别关注本协议条款中加黑加粗字体部分，并注意如下事项：
              </p>
              <p>
                1、您已阅读本协议所有条款，并对当事人有关权利义务和责任限制或免除条款的法律含义有准确无误的理解；
              </p>
              <p>
                2、您已清楚，本协议条款虽是山西众之翼建筑科技有限公司提供的示范条款，但双方已根据业务实际就有关条款进行充分协商并达成一致，也根据您的要求做出了必要修改，故本协议任何时候您将不会主张为“格式合同”；
              </p>
              <p>
                3、您也确保提交给山西众之翼建筑科技有限公司的有关证件及资料是真实、完整、准确、合法、有效的；
              </p>
              <p>
                4、您已确认有权在本协议上签字；签署本协议后即视为您同意本协议全部条款；
              </p>
              <p>
                5、您同意本协议采用电子签章的方式签署，并以数据电文的形式予以存储和保管。您已知悉数据电文形式的协议文本与纸质文本具有同等的法律效力。
              </p>
              <p>6、您已确认任何欺诈，违法行为将要承担的相应法律后果；</p>
              <p>7、您将本着诚实、信用的原则，善意签订并依约履行协议。</p>
              <p ref="page_2">
                特别提醒:在您确认本合同之前，请确保您已经仔细阅读并充分理解本协议的全部内容，以及与本协议相关的产品信息和平台规则。如您不同意其中的任何条款，请停止操作，如有意外，请咨询蓝途云平台客服。
              </p>

              <div>
                <p>协议编号：{{ currentdata.xyCode }}</p>
                <p style="text-align: center; font-weight: bold">
                  项目委托协议
                </p>
                <p>委托方（甲方）：山西众之翼建筑科技有限公司</p>
                <p>被委托方（乙方）： {{ currentdata.userName }}</p>
                <p>身份证号： {{ currentdata.userIdCard }}</p>
                <p>
                  根据《中华人民共和国合同法》，结合甲方承建的具体情况，双方达成如下协议：
                </p>
                <p>第一条工程概况</p>
                <p>
                  “ {{ currentdata.projectCode }} 项目”，钢结构建模工作范畴：
                  <span
                    v-for="(item, index) in currentdata.requireList"
                    :key="index"
                  >
                    {{ index + 1 }}.{{ item }}
                  </span>
                </p>
                <p>项目基本概况详见《项目初审记录》</p>
                <p>第二条协议使用的标准和适用法律</p>
                <p>2.1 采用国家现行的法律、法规及标准规范、文件。</p>
                <p>
                  2.2 符合山西众之翼建筑科技有限公司《钢结构深化建模技术标准
                  》。
                </p>
                <p>2.3 满足蓝途云工厂约定的其他建模作业相关标准。</p>
                <p>第三条甲方权利与义务</p>
                <p>3.1 向乙方提供的钢结构专业施工蓝图（或电子版蓝图）；</p>
                <p>3.2向乙方提供钢结构施工计划和施工图纸绘制的需求计划；</p>
                <p>
                  3.3甲方有权过程监督和检查乙方图纸绘制进度，乙方应如实汇报进度情况。
                </p>
                <p>3.4 按合同约定，及时足额支付图纸绘制费用。</p>
                <p>第四条乙方权利与义务</p>
                <p>
                  4.1
                  乙方负责将甲方提供的本工程钢结构专业施工图纸绘制，并将转化成果移交给甲方。未经甲方允许<span
                    style="font-weight: bold"
                    >严禁将施工蓝图和转化成果泄密给第三方</span
                  >。
                </p>
                <p ref="page_3">
                  4.2乙方需按甲方编制的图纸绘制需求按期完成图纸绘制并移交转化成果。乙方图纸绘制进度和质量应满足现场施工进度要求。
                </p>
                <p>
                  4.3
                  乙方图纸深化设计过程中对施工图的疑问应告知甲方，由甲方向施工图设计院进行质疑。
                </p>
                <p>第五条工期、合同价款及付款方式</p>
                <p>5.1 项目签订工期 {{ currentdata.expDateSt }}</p>
                <p>5.2 价款：人民币{{ currentdata.workPriceStr }}元</p>
                <p>5.3 扣款及收益加成部分依据蓝途云工厂相关规定执行。</p>
                <p>5.4 付款方式</p>
                <p>（1）电子兑付。</p>
                <p>第六条违约责任及图纸深化要求</p>
                <p>
                  6.1
                  乙方应在确保工期的同时保证施工图纸的质量。甲方、设计院、业主等各方对图纸的审批确认并不能减免乙方承担施工图纸的责任。若由于乙方原因造成甲方直接经济损失（只包含因图纸深化问题导致工程制作，现场安装出问题），乙方应承担相应的责任。蓝途云工厂将按照平台相关规则进行处理。
                </p>
                <p>
                  6.2
                  甲方未按约定期期限支付图纸绘制费用，应按银行关于延期付款的规定支付利息，且乙方有权拒绝继续进行图纸深化设计工作。
                </p>
                <p>争议解决方法</p>
                <p>
                  7.1
                  在合同履行过程中发生争议，双方应协商解决，也可以请示有关方面进行调解。当事人不愿协商、调解解决或协商、调解不成的，可申请仲裁或向甲方所在地人民法院提起诉讼。
                </p>
                <p>第八条合同生效与解除</p>
                <p>8.1 本合同经甲乙双方签字并加盖公章后立即生效。</p>
                <p>
                  8.2
                  乙方完成全部图纸绘制的相关工作，甲方足额支付费用，本合同自动解除。
                </p>
                <p>第九条 其他</p>
                <p>9.1本合同一式一份，为电子文本合同。</p>
              </div>
            </div>
          </div>
          <p class="protoAgree" style="margin-top: 30px; text-align: center">
            <el-checkbox
              v-model="checked"
              :disabled="remainingTime > 0"
            ></el-checkbox>
            我已阅读并同意<span class="pointer" v-if="remainingTime > 0"
              >({{ remainingTime }}s)</span
            >
          </p>
          <div style="margin-top: 20px" class="handlebut">
            <el-button
              :disabled="!checked || remainingTime > 0"
              class="confirm_but"
              type="primary"
              @click="downloadPDF()"
              >确认</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>

    <div>
      <el-dialog
        custom-class="settleDialog"
        :visible.sync="dialogSettle"
        width="548px"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="settle_infor">
          <p>项目编号：{{ proSettledata.projectCode }}</p>
          <p>结算价格：{{ proSettledata.outsidePrice }}</p>
        </div>
        <div style="min-height: 300px">
          <el-table
            border
            :data="proSettledata.tableData"
            style="width: 1120px"
            class="center-table"
          >
            <el-table-column prop="isPay" label="一级类型">
              <template slot-scope="scope">
                <span>{{
                  scope.row.oneName + "(" + scope.row.onePercentage + ")"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="isPay" label="二级类型">
              <template slot-scope="scope">
                <span>{{
                  scope.row.twoName + "(" + scope.row.twoPercentage + ")"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="des" label="备注" align="center">
            </el-table-column>
            <el-table-column prop="progress" label="完成度" align="center">
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Global from "@/assets/js/global";
import streamSaver from "streamsaver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
  components: {},
  data() {
    return {
      authenType: 1,
      pageSizes: [10],
      content: "",
      queryLimit: {
        page: 1,
        limit: 10,
        searchText: "",
        state: "",
      },
      SettleLimit: {
        page: 1,
        limit: 10,
      },
      checked: false,
      blurborder: "",
      fileList: [],
      fileListlen: 1,
      totalCount: 0,
      usertype: 1,
      bodyclass: "bodyopen",
      currentdata: {},
      dialogproHandle: false,
      remainingTime: 10,
      prodata: {},

      // 阶段性结算
      settleList: [],
      settleCount: 0,
      dialogSettle: false,
      proSettledata: [],
    };
  },
  created() {
    this.getFileList();
  },
  mounted() {},

  methods: {
    changeauthenType(type) {
      var that = this;
      that.authenType = type;

      if (type == 2) {
        this.getSettlement();
      } else {
        this.queryLimit.page = 1;
        this.getFileList();
      }
    },
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        }
      }, 1000);
    },
    //获取文件集合
    getFileList() {
      this.$http
        .get("Api/outside/outsideSignUp/userSignUp", {
          params: this.queryLimit,
        })
        .then((res) => {
          this.fileList = res.data.data;
          this.fileListlen = res.data.data.length;
          this.totalCount = res.data.count;
        });
    },

    getSettlement() {
      this.$http
        .get("Api/yw/outsideStage/getPgaeList", {
          params: this.SettleLimit,
        })
        .then((res) => {
          console.log(res);
          this.settleList = res.data.data;
          this.settleListlen = res.data.data.length;
          this.settleCount = res.data.count;
        });
    },

    viewprohandle(data) {
      this.prodata = data;
      this.$http
        .get(
          "Api/outside/outsideSignUp/signView?projectTaskId=" +
            data.projectTaskId
        )
        .then((res) => {
          this.currentdata = res.data.data;
        });

      this.dialogproHandle = true;
      this.remainingTime = 10;
      this.startCountdown();
    },
    viewPdf(data) {
      this.prodata = data;
      window.open(Global.baseURL + data.signFile.fileUrl);
    },

    viewSettle(data) {
      console.log(data);
      this.dialogSettle = true;
      this.$http
        .get("Api/yw/outsideStage/getByProject?projectId=" + data.projectTaskId)
        .then((res) => {
          console.log(res.data.data);

          this.proSettledata = res.data.data;
        });
    },

    changeSettlestate(data, type) {
      console.log(data);
      this.$http
        .post(
          "Api/yw/outsideStage/updateStage?id=" + data.id + "&state=" + type
        )
        .then((res) => {
          if (res.data.result) {
            this.$message.success(res.data.data);
            this.SettleLimit.page = 1;
            this.getSettlement();
          } else {
            this.$message.error("操作失败");
          }
        });
    },

    handleClose(done) {
      this.dialogproHandle = false;
      this.dialogSettle = false;
      // this.$refs.modal.style.display = "none";
      // done();
    },

    handleSizeChange(val) {
      this.queryLimit.limit = val;
      this.getFileList();
    },
    // 点击第几页
    handleCurrentChange(val) {
      this.queryLimit.page = val;
      this.getFileList();
    },

    changeState() {
      this.queryLimit.page = 1;
      this.getFileList();
    },

    async downloadPDF() {
      const element2 = this.$refs.agreementCon;
      console.log(element2.scrollHeight);
      var conheight = element2.scrollHeight + 120;
      element2.style.height = conheight + "px";
      this.dialogproHandle = false;
      const element = document.getElementById("pdf-details"); // 需要截取的内容的id
      //创绘制切割后绘制canvas用的canvas标签以及对应的context对象
      const perCanvas = document.createElement("canvas");
      perCanvas.style.backgroundColor = "#fff";
      const context = perCanvas.getContext("2d");

      // 将需要下载的html标签转成canvas标签，并获取对应的base64码
      const elem = document.getElementById("pdf-details");
      const canvas = await html2canvas(elem);
      const canvasData = canvas.toDataURL("image/jpeg", 1.0);
      element2.style.height = "300px";
      // pdf的尺寸
      const pdfWidth = canvas.width;
      const pdfHeight = pdfWidth * 1.414;

      //切割后的canvas图片的宽高，就等于每页pdf的宽高
      perCanvas.width = canvas.width;
      perCanvas.height = pdfHeight;

      // 每张图片的高度：适当减少100，上下各留50页边距
      const perHeight = pdfHeight - 80;

      // 计算切割次数
      let splitCount = Math.ceil(canvas.height / perHeight);
      if (splitCount * perHeight < canvas.height) splitCount++;

      //创建img对象，加载完整的canvas图片
      const img = new Image();
      img.src = canvasData;

      //创建pdf对象
      const pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
      //待图片加载完成
      await setTimeout(() => {}, 5000);

      //切割canvas图片，贴到每一页pdf中
      for (let i = 0; i < splitCount; i++) {
        const startY = i * perHeight; // 起始y坐标

        // 清空画布
        context.clearRect(0, 0, perCanvas.width, pdfHeight);
        context.fillStyle = "#fff";
        context.fillRect(0, 0, perCanvas.width, pdfHeight);
        // 绘制当前切割区域的图片
        context.drawImage(
          img,
          0,
          startY,
          perCanvas.width,
          perHeight,
          0,
          0,
          perCanvas.width,
          perHeight
        );
        const perCanvasData = perCanvas.toDataURL("image/jpeg", 1.0);
        pdf.addImage(
          perCanvasData,
          "JPEG",
          0,
          40,
          perCanvas.width,
          perCanvas.height
        );
        if (i < splitCount - 1) pdf.addPage();
      }

      pdf.save("委托协议.pdf");
      var file = pdf.output("datauristring");
      console.log(file);
      var formdata = new FormData();
      formdata.append("file", file); // 文件对象
      this.$http
        .post(
          "Api/yw/projectTaskOutside/signXY?id=" + this.prodata.projectTaskId,
          formdata
        )
        .then((res) => {
          console.log(res.data.result);
          if (res.data.result) {
            this.$message.success("签署成功");
            this.changeState();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      // let am = h < 12 ? "AM" : "PM";
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      return y + "-" + MM + "-" + d + "  " + h + ":" + m;
    },
    formatDay: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    },
  },
};
</script>

<style>
@import "@/assets/css/projectHandle.css";
.searchblur {
  border: 1px solid #3d7eff !important;
}
.head_sel {
  height: 60px;
  /* background-color: #fff; */
}
.sel_left {
  padding-top: 10px;
  padding-bottom: 10px;
  float: left;
}
.filecenter_form {
  text-align: left;
  /* padding: 0px 20px; */
}
.joinPro_con {
  background: #fff;
  padding-bottom: 20px;
  /* padding: 0px 20px; */
}
.joinPro_tab {
  padding: 20px 20px;
  /* padding-top: 20px; */
}
.center-table th.el-table__cell > .cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
