import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "../views/login"
import Register from "../views/register"
import Message from "../views/message"

import Home from "../views/home"
import NotFound from "../views/errorPage/404"
import Forbidden from "../views/errorPage/403"
import Layout from "../views/layout"

import OutsidePro from "../views/outsidePro"
import OutsideProcard from "../views/outsideProcard"
import WebUseterm from "../views/webUseterm"
import Authenticat from "../views/authenticat"

import projectQuestion from "../views/projectQuestion"

import fileCenter from "../views/fileCenter"
import earningsView from "../views/earningsView"
import leftNavigation from "../views/leftNavigation"
import myProblem from "../views/myProblem"
import historyProblem from "../views/historyProblem"
import orderNotice from "../views/orderNotice"

import engineerAuth from "../views/engineerAuth"
import projectDetail from "../views/projectDetail"
import projectHandle from "../views/projectHandle"


Vue.use(VueRouter)

// 初始化路由
const routes = [
    {
        path: "",
        component: Layout,
        name: 'container',
        redirect: "home",
        meta: {
            requiresAuth: true,
            name: "首页"
        },
        children: [
            {
                path: "home",
                component: Home,
                name: "home",
                meta: {
                    // 匹配规则
                    name: "首页",
                    icon: "icon-name"
                }
            },

            {
                path: "message",
                component: Message,
                name: "message",
                meta: {
                    // 匹配规则
                    name: "消息",
                    icon: "icon-name",
                    keepAlive: false, // 
                }
            },
            {
                path: "webUseterm",
                component: WebUseterm,
                name: "webUseterm",
                meta: {
                    // 匹配规则
                    name: "我的",
                    icon: "icon-name"
                }
            },

            {
                path: "leftNavigation",
                component: leftNavigation,
                name: "leftNavigation",
                redirect: "leftNavigation/projectDetail",
                meta: {
                    requiresAuth: true,
                    name: "首页"
                },
                children: [
                    {
                        path: "projectDetail",
                        component: projectDetail,
                        name: "projectDetail",
                        meta: {
                            // 匹配规则
                            name: "我的",
                            icon: "icon-name",
                            keepAlive: false, // 
                        }
                    },
                    {
                        path: "myProblem",
                        component: myProblem,
                        name: "myProblem",
                        meta: {
                            // 匹配规则
                            name: "我的",
                            icon: "icon-name",
                            keepAlive: false, // 
                        }
                    },

                    {
                        path: "historyProblem",
                        component: historyProblem,
                        name: "historyProblem",
                        meta: {
                            // 匹配规则
                            name: "我的",
                            icon: "icon-name",
                            keepAlive: false, // 
                        }
                    },
                    {
                        path: "projectQuestion",
                        component: projectQuestion,
                        name: "projectQuestion",
                        meta: {
                            // 匹配规则
                            name: "身份认证",
                            icon: "icon-name"
                        }
                    },
                    {
                        path: "authenticat",
                        component: Authenticat,
                        name: "authenticat",
                        meta: {
                            // 匹配规则
                            name: "身份认证",
                            icon: "icon-name"
                        }
                    },
                    {
                        path: "fileCenter",
                        component: fileCenter,
                        name: "fileCenter",
                        meta: {
                            // 匹配规则
                            name: "文件中心",
                            icon: "icon-name"
                        }
                    },
                    {
                        path: "earningsView",
                        component: earningsView,
                        name: "earningsView",
                        meta: {
                            // 匹配规则
                            name: "收益详情",
                            icon: "icon-name"
                        }
                    },
                    {
                        path: "projectDetail",
                        component: projectDetail,
                        name: "projectDetail",
                        meta: {
                            // 匹配规则
                            name: "详情",
                            icon: "icon-name"
                        }
                    }, {
                        path: "projectHandle",
                        component: projectHandle,
                        name: "projectHandle",
                        meta: {
                            // 匹配规则
                            name: "项目处理",
                            icon: "icon-name"
                        }
                    }
                ]
            },
            {
                path: "/orderNotice",
                component: orderNotice,
                name: "orderNotice",
                meta: {
                    // 匹配规则
                    name: "项目须知",
                    icon: "icon-name"
                }
            },

            {
                path: "/OutsideProcard",
                component: OutsideProcard,
                name: "OutsideProcard",
                meta: {
                    // 匹配规则
                    name: "项目须知",
                    icon: "icon-name",
                    keepAlive: false, //
                    cache: false,
                }
            },
            {
                path: "/engineerAuth",
                component: engineerAuth,
                name: "engineerAuth",
                meta: {
                    // 匹配规则
                    name: "项目须知",
                    icon: "icon-name"
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: "/403",
        component: Forbidden
    },
    {
        path: "*",
        component: NotFound
    }

]

/**
 * 根据用户的权限不同，所能看到的页面和可操作性也不同
 *  admin -> 所有页面都可以看得到
 *  vip -> 属于vip的权限
 *  svip -> 更多额vip的权限
 * 
 * addRouter()
 */
// 准备动态加载的路由


const router = new VueRouter({
    mode: 'hash',
    routes,
});


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

// 路由导航守卫 to：将要访问的路径，form：代表从哪个路径跳转而来，next：代表一个函数，表示放行
router.beforeEach((to, form, next) => {

    let user = window.sessionStorage.getItem('token');

    if (to.fullPath == "/home") {
        if (user) {
            next({ path: '/leftNavigation' })
        } else {
            next()
        }
    } else if (to.fullPath.includes("/login")) {
        next()
    } else if (to.fullPath.includes("/register")) {
        next()
    } else if (to.fullPath.includes("/webUseterm")) {
        next()
    } else if (to.fullPath.includes("/orderNotice")) {
        next()
    } else {
        if (!user) {
            sessionStorage.setItem('from', to.fullPath);
            next({
                path: ''
            })
        } else {
            next()
        }
    }


    if (to.meta && to.meta.cache === false) {
        next({
            path: to.path,
            params: to.params,
            query: to.query,
            // 使用replace: true或者new instance: true来确保不被缓存
            replace: true
        });
    } else {
        next();
    }
    // next();
});

export default router
