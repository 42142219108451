<template>
  <div class="outsidePro" style="background: #f5f6fa">
    <div class="outsidecard_box">
      <!-- 筛选 -->
      <div>
        <div class="head_sel" style="margin-top: 0px">
          <div class="sel_left">
            <el-cascader
              v-model="realmIds"
              :options="realmlist"
              :show-all-levels="false"
              placeholder="结构类型"
              style=""
              ref="cascaderHandle"
              filterable
              clearable
              @change="changeRealm"
            >
            </el-cascader>
            <el-select
              v-model="selectExDateIndex"
              placeholder="工期"
              @change="searchExDate"
              clearable
            >
              <el-option
                v-for="item in exdateOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.sortState"
              placeholder="收益"
              @change="search()"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="sel_right">
            <el-select
              v-model="form.stateFlag"
              placeholder="状态"
              @change="search()"
              clearable
            >
              <el-option
                v-for="item in stateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!--            <el-checkbox v-model="form.stateFlag" @change="search()" >待抢单</el-checkbox>-->
          </div>
        </div>
      </div>

      <!-- 项目列表 -->

      <div class="outsidecard_box">
        <ul class="outsidecard_list">
          <li v-for="(item, index) in newProlist" :key="index">
            <div class="tabstyle">
              <img
                class="tabicon"
                src="../../assets/images/outsidepro/仅建模.png"
              />

              <el-tooltip
                :disabled="isShowTooltip"
                effect="light"
                :content="
                  item.projectTaskPojo.projectCode +
                  '&' +
                  item.parentRealmStr +
                  '-' +
                  item.realmStr
                "
                placement="right"
                popper-class="ques_popover"
              >
                <p
                  @mouseover="
                    onMouseOver(
                      item.projectTaskPojo.projectCode +
                        '&' +
                        item.parentRealmStr +
                        '-' +
                        item.realmStr
                    )
                  "
                >
                  <span
                    :ref="
                      item.projectTaskPojo.projectCode +
                      '&' +
                      item.parentRealmStr +
                      '-' +
                      item.realmStr
                    "
                  >
                    {{
                      item.projectTaskPojo.projectCode +
                      "&" +
                      item.parentRealmStr +
                      "-" +
                      item.realmStr
                    }}
                  </span>
                </p>
              </el-tooltip>
            </div>

            <p class="card_title">{{ item.outsidePrice }}元</p>
            <div class="card_infor">
              <!--              <span class="signed" v-if="item.state == 1">待签单</span>-->
              <!--              <span class="signed" v-else>已签约</span>-->
              <!--              <span>{{item.projectTaskPojo.stepState==1?'常规':item.projectTaskPojo.stepState==3?'仅建模':''}}</span>-->
              <span
                class="signed"
                v-if="item.state == 1"
                v-text="
                  $date.Djs_timeList3(serverTime, item.exPectDate) + '交付'
                "
              ></span>
              <span
                v-else
                class="signed"
                style="background-color: #fee0b9 !important; color: #954d0e"
                >平台已担保
              </span>

              <el-popover
                popper-class="review-popper"
                placement="right"
                width="492"
                trigger="click"
                v-if="item.jointReviewEntity!=null"
                ref="popover"
              >
                <div class="review_box">
                  <div class="review_head">
                    <p>
                      <img
                        src="../../assets/images/outsidepro/xm-1.png"
                        alt=""
                      />{{ item.projectTaskPojo.projectCode }}
                    </p>
                    <p @click="closePopover">
                      <img src="../../assets/images/outsidepro/gb.png" alt="" />
                    </p>
                  </div>
                  <div class="review_inforbox">
                    <div class="review_inforlist">
                      <p class="doticon"></p>
                      <p>结构类型：</p>
                      <p>{{item.jointReviewEntity.primaryStructure}}-{{ item.jointReviewEntity.secondaryStructure }}</p>
                    </div>
                    <div class="review_inforlist">
                      <p class="doticon"></p>
                      <p>附属构件：</p>
                      <p>{{ item.jointReviewEntity.attachStructure }}</p>
                    </div>
                    <div class="review_inforlist">
                      <p class="doticon"></p>
                      <p>建筑方位：</p>
                      <div class="orientation">
                        <p>
                          <img
                            src="../../assets/images/outsidepro/ceng.png"
                            alt=""
                          />层：{{ item.jointReviewEntity.storey }}
                        </p>
                        <p>
                          <img
                            src="../../assets/images/outsidepro/mj.png"
                            alt=""
                          />面积：{{ item.jointReviewEntity.area }}
                        </p>
                        <p>
                          <img
                            src="../../assets/images/outsidepro/bg.png"
                            alt=""
                          />标高：{{ item.jointReviewEntity.elevation }}
                        </p>
                        <p>
                          <img
                            src="../../assets/images/outsidepro/qt.png"
                            alt=""
                          />其他：{{ item.jointReviewEntity.otherDes }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p style="clear: both"></p>
                  <div class="precautions"><span></span>注意事项</div>
                  <div class="precautions_box">
                    <p>
                      {{ item.jointReviewEntity.des }}
                    </p>

                  </div>
                </div>
                <span class="review pointer" slot="reference"
                  ><img
                    src="../../assets/images/outsidepro/xz.png"
                    alt=""
                  />会审记录</span
                >
              </el-popover>
            </div>

            <div class="card_infor_2">
              <p>预计工作时长：{{ item.estimateTime }}h</p>
              <p>交付时间：{{ item.exPectDateStr }}</p>

              <p class="card_file">
                项目资料：

                <el-popover
                  popper-class="tab_popover"
                  placement="right"
                  width="302"
                  trigger="click"
                  v-if="item.requireList.length > 0"
                >
                  <div style="padding: 20px 20px" class="require_popper">
                    <p>项目要求</p>
                    <!-- <ul
                            v-for="(item, index) in item.requireList"
                            :key="index"
                          > -->
                    <ul>
                      <li
                        v-for="(item, index) in item.requireList"
                        :key="index"
                      >
                        {{ index + 1 }}、{{ item }}
                      </li>
                    </ul>
                  </div>
                  <span
                    class="pointer"
                    slot="reference"
                    style="margin-right: 5px"
                    @mouseover="changeimg('1')"
                    ><img :src="require_img" alt="" />项目要求
                  </span>
                </el-popover>

                <el-popover
                  popper-class="tab_popover"
                  placement="right"
                  width="600"
                  trigger="click"
                >
                  <el-table :data="item.fileList" border>
                    <el-table-column
                      property="fileName"
                      label="名称"
                      width="200"
                    ></el-table-column>
                    <el-table-column
                      property="fileSizeStr"
                      label="大小"
                      width="100"
                    ></el-table-column>
                    <el-table-column
                      property="crtDateStr"
                      label="时间"
                      width="200"
                    ></el-table-column>
                    <el-table-column label="操作" width="">
                      <template slot-scope="scope">
                        <span
                          class="pointer down"
                          style="margin-left: 10px"
                          @click="download(scope.row)"
                          >下载</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>

                  <span class="pointer" slot="reference"
                    ><img
                      src="../../assets/images/outsidepro/file_icon.png"
                      alt=""
                    />项目附件
                  </span>
                </el-popover>
              </p>
              <p>
                发布时间：
                <span
                  v-text="$date.formatDate(item.notarizeDate, 'yyyy-M-d')"
                ></span>
              </p>
            </div>

            <p
              class="demand"
              v-if="item.state == 1 && item.signUpFlag == 0"
              @click="isconnectOrder(item, '1')"
              style="
                background: #f0f4fe;
                background-color: #f0f4fe !important;
                color: #7856fd !important;
                border: 1px solid #7856fd !important;
              "
            >
              去报名
            </p>
            <p
              v-else-if="item.state == 1 && item.signUpFlag == 1"
              class="demand"
              @click="isconnectOrder(item, '2')"
              style="
                background-color: #dcd4ff !important;
                color: #7856fc !important;
                border: none !important;
              "
            >
              报名成功
            </p>
            <p
              v-else-if="item.state == 2"
              class="demand"
              style="
                background-color: #dcd4ff !important;
                color: #7856fc !important;
                border: none !important;
              "
            >
              报名结束
            </p>
            <p
              class="demand"
              style="
                background-color: #f0efef !important;
                color: #464646 !important;
                border: none !important;
              "
              v-else
            >
              已签单
            </p>

            <p class="button_txt" v-if="item.state > 2">
              由 {{ item.orderUserNickName }} 签单
            </p>
            <p
              v-if="item.state == 1"
              style="
                text-align: center;
                font-size: 12px;
                color: #7856fd;
                margin-top: 8px;
              "
            >
              <span v-if="item.signUpUserCount > 0"
                >{{ item.signUpUserCount }}人报名|</span
              >

              <span
                v-if="$date.Djs_timeList(serverTime, item.signUpEndDate) !== ''"
                v-text="$date.Djs_timeList(serverTime, item.signUpEndDate, 1)"
              ></span>
              <span v-else>即将结束</span>
            </p>
          </li>
        </ul>
        <p style="clear: both"></p>
        <div style="">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-sizes="[3, 10, 20, 50, 100]"
            :page-size="form.limit"
            layout="total, prev, pager, next, jumper"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="problemtit"
      custom-class="addquestionlog"
      :visible.sync="orderStatusDialog"
      :close-on-click-modal="false"
      width="375px"
      :before-close="handleClose"
    >
      <div style="height: 280px">
        <div v-if="orderstate == 1">
          <p class="order_success_tit">
            <img
              src="../../assets/images/outsidepro/check.png"
              alt=""
            />抢单成功
          </p>
          <div class="order_success">
            <p>接单项目：{{ takeorderData.projectTaskPojo.projectCode }}</p>
            <!--            <p>-->
            <!--              工程量：{{ takeorderData.projectTaskPojo.projectTaskQuantity }}t-->
            <!--            </p>-->
            <p>最迟交付时间：{{ takeorderData.exPectDateStr }}</p>
            <p>预估收益：￥{{ takeorderData.outsidePrice }}</p>
          </div>
          <div class="order_opera">
            <p class="back_but pointer" @click="search()">返回</p>
            <p class="gomyPro_but pointer" @click="gomyPropage()">
              进入我的项目
            </p>
          </div>
        </div>

        <div v-else-if="orderstate == 2">
          <p class="error_icon">
            <img src="../../assets/images/outsidepro/ordererror_1.png" alt="" />
          </p>
          <p class="error_txt">您选择的项目刚刚被接走</p>
          <p class="error_txt_tip">去接其他项目</p>
          <p class="error_back_but pointer" @click="search()">返回</p>
        </div>

        <div v-else-if="orderstate == 3">
          <p class="error_icon">
            <img src="../../assets/images/outsidepro/ordererror_2.png" alt="" />
          </p>
          <p class="error_txt">抢单出现未知错误,请</p>
          <p class="error_txt_tip">联系客户经理</p>
          <p class="error_back_but pointer" @click="search()">返回</p>
        </div>

        <div v-else-if="orderstate == 4">
          <p class="error_icon">
            <img src="../../assets/images/outsidepro/ordererror_2.png" alt="" />
          </p>
          <p class="error_txt">您的接单已达到上线，</p>
          <p class="error_txt">请完成项目等待审核通过后重试</p>
          <p class="error_back_but pointer" @click="search()">返回</p>
        </div>
      </div>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="addQuesDialog = false">取 消</el-button>
        <el-button class="confirm_but" type="primary" @click="comproblem()"
          >完成</el-button
        >
      </span> -->
    </el-dialog>
    <div class="ordertip_box">
      <el-dialog
        custom-class="orderdialog"
        :visible.sync="takeorderDialog"
        width="840px"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div style="padding-bottom: 20px">
          <p class="ordertip_tit">
            <img src="../../assets/images/outsidepro/pro_icon.png" alt="" />{{
              takeorderData.projectTaskPojo.projectCode
            }}
          </p>

          <div class="orderinfor_box">
            <!-- <div class="orderinfor orderinfor_1">
              <p class="infor_icon">
                <img
                  src="../../../assets/images/outsidepro/dialog_icon1.png"
                  alt=""
                />
              </p>
              <div class="infor_con">
                <p class="infor_num">
                  {{ takeorderData.projectTaskPojo.projectTaskQuantity }}t
                </p>
                <p class="infor_txt">工程量</p>
              </div>
            </div> -->

            <div class="orderinfor orderinfor_2">
              <p class="infor_icon">
                <img
                  src="../../assets/images/outsidepro/dialog_icon2.png"
                  alt=""
                />
              </p>

              <div class="infor_con">
                <p class="infor_txt">申报指导价格</p>
                <p class="infor_num">
                  ￥{{ takeorderData.outsidePrice }}
                  <el-popover placement="right" width="300" trigger="click">
                    <el-timeline :reverse="reverse">
                      <el-timeline-item
                        v-for="(activity, index) in activities"
                        :key="index"
                        :timestamp="activity.expectDateStr"
                      >
                        {{ activity.workPrice }}元 {{ activity.workHours }}h
                        {{ activity.userNickName }}
                      </el-timeline-item>
                    </el-timeline>
                    <!-- <el-button slot="reference">click 激活</el-button> -->
                    <span slot="reference" class="viewpover pointer">查看</span>
                  </el-popover>
                </p>
                <p class="infor_txt">申报价格</p>
                <div style="border: 1px solid #91959f">
                  <el-input-number
                    v-model="workPrice"
                    @keydown.native="onKeydown"
                    :min="Number(workstepdata.minWorkPrice)"
                    :max="Number(workstepdata.maxWorkPrice)"
                    :step="Number(workstepdata.changeWorkPrice)"
                    label="预估收益"
                  ></el-input-number>
                </div>
              </div>
            </div>

            <div class="orderinfor orderinfor_2">
              <p class="infor_icon">
                <img
                  src="../../assets/images/outsidepro/dialog_icon3.png"
                  alt=""
                />
              </p>
              <div class="infor_con">
                <p class="infor_txt">预计工作时长</p>
                <p class="infor_num">{{ takeorderData.estimateTime }}h</p>
                <p class="infor_txt">评估工作时长</p>
                <div style="border: 1px solid #91959f">
                  <el-input-number
                    v-model="workHours"
                    @keydown.native="onKeydown"
                    :min="Number(workstepdata.minWorkHours)"
                    :max="Number(workstepdata.maxWorkHours)"
                    :step="0.5"
                    label="工时"
                  ></el-input-number>
                </div>
              </div>
            </div>

            <div class="orderinfor orderinfor_3">
              <p class="infor_icon">
                <img
                  src="../../assets/images/outsidepro/dialog_icon3.png"
                  alt=""
                />
              </p>
              <div class="infor_con">
                <p class="infor_txt">最迟交付时间</p>
                <p class="infor_num">{{ takeorderData.exPectDateStr }}</p>
                <p class="infor_txt">承诺最迟交付时间</p>
              </div>
              <div
                style="
                  border: 1px solid #91959f;
                  position: absolute;
                  top: 76px;
                  left: 60px;
                "
              >
                <!-- <el-input
                    v-model="workHours"
                    placeholder="评估时间填写(单位:时)"
                  >
                  </el-input> -->

                <el-date-picker
                  v-model="value1"
                  type="datetime"
                  placeholder="选择日期"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </div>
          </div>

          <div
            class="orderrequired"
            v-if="takeorderData.requireList.length > 0"
          >
            <p class="title">项目要求</p>
            <ul>
              <li
                v-for="(item, index) in takeorderData.requireList"
                :key="index"
              >
                <p><span>·</span>{{ item }}</p>
              </li>
            </ul>
          </div>

          <!-- <div class="ordertip_txt">
            <p>接单项目：{{ takeorderData.projectAlias }}</p>
            <p>最迟交付：{{ takeorderData.exPectDateStr }}</p>
            <p>预估收益：￥{{ takeorderData.outsidePrice }}</p>
          </div> -->
          <div style="width: 100%; height: 36px; margin-top: 20px">
            <p class="protoAgree pointer" style="text-align: center">
              <el-checkbox v-model="checked"></el-checkbox>
              请阅读并同意<span @click="goorderNotice('1')">《项目须知》</span
              >和<span @click="goorderNotice('2')">《构件零件编号规范》</span>
            </p>
          </div>
          <div style="text-align: center">
            <el-button @click="handleClose()">取消</el-button>
            <el-button
              :disabled="condisabled"
              type="primary"
              @click="confirmorder()"
              >确认</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>

    <el-dialog
      custom-class="codedialog"
      :visible.sync="codeDialog"
      width="375px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="code_infor">
        <p class="code_tit">蓝途管控云客户端小程序</p>
        <p class="code_tit2">扫描下方二维码，下载蓝途管控云平台</p>
        <p class="dialogcode_img">
          <img src="../../assets/images/home/vscode.png" alt="" />
        </p>
      </div>
    </el-dialog>
    <isAuth
      ref="auth"
      v-if="isAuthenticShow"
      :isAuthenticShow="isAuthenticShow"
    ></isAuth>
  </div>
</template>

<script>
import Global from "@/assets/js/global";
import { formatDate } from "@/assets/js/dateUtils";
import isAuth from "./component/isAuthentic.vue";
import streamSaver from "streamsaver";
export default {
  components: {
    isAuth,
  },
  name: "Home",
  data() {
    return {
      realmIds: [],
      realmlist: [],
      selectExDateIndex: "",
      serverTime: "",
      value1: "",
      exdateOption: [
        {
          value: "0",
          label: "3天以内",
          type: 0,
        },
        {
          value: "1",
          label: "3天以上",
          type: 1,
        },
        {
          value: "2",
          label: "7天以上",
          type: 1,
        },
        {
          value: "3",
          label: "10天以上",
          type: 1,
        },
      ],
      form: {
        page: 1,
        limit: 10,
        searchText: "",
        realmId: "",
        day: "",
        dayType: "0",
        sortType: "0",
        sortState: "",
        stateFlag: "",
      },
      checked: false,
      downloadUrl: Global.downloadUrl,
      imageUrl: Global.baseURL,
      inCount: 0,
      residueCount: 0,
      newProlist: [],
      newProlistlen: 0,
      haveProlist: [],

      totalCount: 0,
      loading: true,
      options: [
        {
          value: "1",
          label: "收益最高",
        },
        {
          value: "0",
          label: "收益最低",
        },
      ],
      stateOptions: [
        {
          value: "1",
          label: "报名中",
        },
      ],
      isShowTooltip: false,
      problemtit: "",
      orderStatusDialog: false,
      codeDialog: false,
      isAuthenticShow: false,
      orderstate: 1,
      isCredentials: window.sessionStorage.getItem("isCredentials"),
      skillCertState: window.sessionStorage.getItem("skillCertState"),
      takeorderDialog: false,
      condisabled: false,
      takeorderData: {
        projectTaskPojo: {
          projectCode: "",
          projectTaskQuantity: 0,
        },
        requireList: [],
        exPectDateStr: "",
        outsidePrice: 0,
        exPectDate: "",
      },
      exPectDate: "",
      selectabletime: ["00:00:00 - 23:59:59"],

      chunks: [],
      // 分片下载大小 5MB
      chunkSize: 1024 * 1024 * 5,
      // 文件总大小(需要请求后端获得)
      fileSize: 0,
      chunksNum: 0,
      require_img: require("@/assets/images/outsidepro/required_icon.png"),
      file_img: require("@/assets/images/outsidepro/file_icon.png"),
      workjson: {},
      workPrice: "",
      workHours: "",
      workstepdata: {
        minWorkHours: "",
        maxWorkHours: "",
        minWorkPrice: "",
        maxWorkPrice: "",
        changeWorkPrice: null,
      },
      type: "",
      id: "",
      reverse: true,
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  computed: {
    pickerOptions() {
      return {
        disabledDate: this.disabledDate,
        selectableRange: this.selectabletime,
      };
    },
    // pickerOptions: {
    //   disabledDate: (time) => {
    //     const dateTime = new Date();
    //     const startDateTime = "";
    //     const endDateTime = this.getexPectDate(); //30000为当前日期之后多少天
    //     return (
    //       time.getTime() < new Date(startDateTime).getTime() ||
    //       time.getTime() > new Date(endDateTime).getTime()
    //     );
    //   },
    //   selectableRange: this.timeRange(),
    // },
  },

  watch: {
    value1(newValue, oldValue) {
      if (newValue == oldValue) {
        return;
      }
      this.selectabletime = this.timeRange(newValue);
    },
  },

  created() {
    // this.getproCount();
    this.getRealmCombox();
    this.getNewprolist();
    this.serverTime = new Date().getTime();
    this.isAnnounce = window.sessionStorage.getItem("userAnnounce");

    if (this.isAnnounce == 0) {
      console.log("222");
      this.dialogAnnounce = true;
    } else {
      this.dialogAnnounce = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log("222");
    // 销毁组件，避免通过vue-router再次进入时，仍是上次的history缓存的状态
    this.$destroy(true);
    next();
  },
  // mounted() {
  //   this.Djs_time();
  // },
  methods: {
    disabledDate(time) {
      const dateTime = new Date();
      const startDateTime = dateTime.setDate(dateTime.getDate() - 1);
      const endDateTime = this.getexPectDate(); //30000为当前日期之后多少天
      return (
        time.getTime() < new Date(startDateTime).getTime() ||
        time.getTime() > new Date(endDateTime).getTime()
      );
    },

    timeRange(time = null) {
      const currentTime = this.takeorderData.exPectDate;
      const currentDate = this.$date.formatDate(currentTime, "yyyy-MM-dd");
      const currentTimeStr = this.$date.formatDate(currentTime, "HH:mm:ss");
      const formDate = time ? this.$date.formatDate(time, "yyyy-MM-dd") : "";
      console.log(formDate);
      console.log(currentDate);
      console.log(currentTimeStr);
      // 证明是今天的时间
      if (!time || currentDate == formDate) {
        console.log("222");
        return [`00:00:00 - ${currentTimeStr}`];
      }
      console.log("333");
      return ["00:00:00 - 23:59:59"];
    },

    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string" && /^[0-9]+$/.test(time)) {
          time = parseInt(time);
        } else if (typeof time === "string") {
          time = time.replace(new RegExp(/-/gm), "/");
        }
        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        if (result.length > 0 && value < 10) {
          value = "0" + value;
        }
        return value || 0;
      });
      return time_str;
    },

    getexPectDate() {
      return this.takeorderData.exPectDate;
    },
    onKeydown(event) {
      if (event.key !== "ArrowUp" && event.key !== "ArrowDown") {
        event.preventDefault();
      }
    },

    onMouseOver(str) {
      // 内容超出，显示文字提示内容
      const tag = this.$refs[str][0];
      const parentWidth = tag.parentElement.offsetHeight; // 获取元素父级可视宽度
      const contentWidth = tag.offsetHeight; // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    changeimg(type) {
      this.require_img = require("@/assets/images/outsidepro/required_icon2.png");
    },
    newProMethod() {
      // this.getMyCount();
      this.getNewprolist();
      // this.gethaveProlist();
      this.getRealmCombox();
    },

    changeRealm(e) {
      if (this.$refs.cascaderHandle.checkedValue.length == 0) {
        this.form.realmId = "";
      } else {
        this.form.realmId = e[1];
      }
      this.search();
    },
    download(row) {
      // 定义 存储所有的分片的数组
      this.chunks = [];
      // 分片下载大小 5MB
      this.chunkSize = 1024 * 1024 * 5;
      // 文件总大小(需要请求后端获得)
      this.fileSize = row.fileSize;
      // 计算分片数量
      this.chunksNum = Math.ceil(this.fileSize / this.chunkSize);
      this.downloadChunkFile(0, row);
    },
    async downloadChunkFile(chunkIdx, row) {
      // if (chunkIdx >= this.chunksNum) {
      //   alert('分片索引不可超过分片数量')
      //   return
      // }
      //
      let start = chunkIdx * this.chunkSize;
      let end = Math.min(start + this.chunkSize - 1, this.fileSize - 1);
      const range = `bytes=${start}-${end}`;
      streamSaver.mitm = Global.serveUrl + "mitm.html?version=2.0.0";
      // 使用 Fetch API 请求文件
      fetch(
        Global.baseURL2 +
          "Api/yw/profile/download?rangeHeader=" +
          range +
          "&path=" +
          row.fileUrl +
          "&fileName=" +
          row.fileName
      ).then((res) => {
        this.chunks.push(res.data);
        // 创建写入流 filename为下载的文件名
        const fileStream = streamSaver.createWriteStream(row.fileName, {
          size: res.headers.get("content-length"),
        });
        const readableStream = res.body;
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream).then(() => {});
        }
        window.writer = fileStream.getWriter();
        const reader = res.body.getReader();
        const pump = () =>
          reader
            .read()
            .then((res) =>
              res.done
                ? window.writer.close()
                : window.writer.write(res.value).then(pump)
            );
        pump();
      });
    },
    searchExDate(e) {
      if (e == "0") {
        this.form.day = 3;
        this.form.dayType = 0;
      } else if (e == "1") {
        this.form.day = 3;
        this.form.dayType = 1;
      } else if (e == "2") {
        this.form.day = 7;
        this.form.dayType = 1;
      } else if (e == "3") {
        this.form.day = 10;
        this.form.dayType = 1;
      } else {
        this.form.day = "";
        this.form.dayType = "";
      }
      this.search();
    },
    search() {
      this.orderStatusDialog = false;
      this.form.page = 1;
      this.getNewprolist();
    },
    getNewprolist() {
      if (this.form.sortState != undefined && this.form.sortState != "") {
        this.form.sortType = 1;
      } else {
        this.form.sortType = 0;
      }

      // this.form.stateFlag = this.form.stateFlag?1:0;
      this.$http
        .get("/Api/yw/projectTaskOutside/getOrder", {
          params: this.form,
        })
        .then((res) => {
          this.newProlist = res.data.data;
          this.newProlistlen = res.data.data.length;
          this.loading = false;
          this.totalCount = res.data.count;
          this.Djs_time();
        });
    },
    Djs_time: function () {
      var _this = this;
      this.timer = setInterval(() => {
        _this.serverTime = _this.serverTime + 1000;
      }, 1000);
    },
    gethaveProlist() {
      this.$http
        .get("/Api/yw/projectTaskOutside/getOnLoadingProject")
        .then((res) => {
          this.haveProlist = res.data.data;
        });
    },

    getMyCount() {
      this.$http.get("/Api/yw/projectTaskOutside/getMyCount").then((res) => {
        this.inCount = res.data.data.inCount;
        this.residueCount = res.data.data.residueCount;
      });
    },

    getRealmCombox() {
      this.$http.get("/Api/yw/realm/getRealmCombox2").then((res) => {
        this.realmlist = res.data.data;
      });
    },
    isconnectOrder(data, type) {
      console.log(data);
      this.checked = false;
      this.takeorderData = data;
      this.takeorderDialog = true;
      this.getconnectdata();

      this.type = type;

      console.log(type);
      if (type == 1) {
        this.workjson.workHours = data.estimateTime;
        this.workjson.workPrice = data.outsidePrice;
        this.value1 = data.exPectDateStr;
        this.id = "";
      } else {
        this.$http
          .get(
            "/Api/outside/outsideSignUp/queryCurrentUserInfo?projectId=" +
              this.takeorderData.projectTaskPojo.projectTaskId
          )
          .then((res) => {
            this.workjson = res.data.data;
            var that = this;
            that.workHours = res.data.data.workHours;
            that.workPrice = res.data.data.workPrice;
            that.value1 = res.data.data.expectDateStr;
            that.id = res.data.data.id;
          });
      }
      this.getProjectStandardLimit();
    },

    getProjectStandardLimit() {
      this.$http
        .get(
          "/Api/yw/projectTaskOutside/getProjectStandardLimit?projectTaskId=" +
            this.takeorderData.projectTaskPojo.projectTaskId
        )
        .then((res) => {
          this.workstepdata = res.data.data;
          this.workHours = this.workjson.workHours;
          this.workPrice = this.workjson.workPrice;
        });
    },
    getconnectdata() {
      this.$http
        .get(
          "/Api/outside/outsideSignUp/queryByProjectId?projectId=" +
            this.takeorderData.projectTaskPojo.projectTaskId
        )
        .then((res) => {
          this.activities = res.data.data;
        });
    },

    confirmorder() {
      this.condisabled = true;
      if (!this.checked) {
        this.$message.error("请阅读并同意《项目须知》和《构件零件编号规范》");
        this.condisabled = false;
        return false;
      }
      this.takeorderDialog = false;
      this.connectOrder();
    },
    connectOrder() {
      if (this.isCredentials == 0) {
        this.isAuthenticShow = true;
        this.condisabled = false;
        this.$nextTick(() => {
          this.$refs.auth.isauthMethod();
        });
      } else if (this.skillCertState == 0) {
        this.isAuthenticShow = true;
        this.condisabled = false;

        this.$nextTick(() => {
          this.$refs.auth.isAuthvocation();
        });
      } else {
        var data = {};
        data.projectTaskId = this.takeorderData.projectTaskPojo.projectTaskId;
        data.workPrice = this.workPrice;
        data.workHours = this.workHours;
        data.expectDateStr = this.value1;

        console.log(this.type);
        var url = "";
        if (this.type == 1) {
          url = "Api/outside/outsideSignUp/add";
        } else {
          url = "Api/outside/outsideSignUp/update";
          data.id = this.id;
        }

        this.$http.post(url, data).then((res) => {
          if (res.data.result == true) {
            this.$message.success(res.data.data);
            this.getNewprolist();
            this.condisabled = false;
          } else {
            if (res.data.code == 201) {
              this.orderstate = 2;
            } else if (res.data.code == 202) {
              this.orderstate = 3;
            } else if (res.data.code == 203) {
              this.orderstate = 4;
            } else {
              this.$message.error(res.data.msg);
              return false;
            }
            this.condisabled = false;
            this.orderStatusDialog = true;
          }
        });
      }
    },
    handleClose() {
      this.orderStatusDialog = false;
      this.takeorderDialog = false;
      this.codeDialog = false;
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.getNewprolist();
    },
    // 点击第几页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getNewprolist();
    },
    gomyPropage() {
      this.orderStatusDialog = false;
      this.$router.push("/leftNavigation/myProblem");
    },
    goorderNotice(type) {
      const { href } = this.$router.resolve({
        path: `/orderNotice`,
        query: { noticeType: type },
      });

      window.open(href, "_blank");
    },
    gopathauthenticat() {
      this.$router.push({
        path: "/leftNavigation/authenticat",
        query: { authenType: 2 },
      });
    },
    goatlasAnima() {
      window.open("https://space.bilibili.com/1176654026", "_blank");
    },
    goWintech() {
      window.open("https://zzyjzkj.com:8080/aboutus.html#introduce", "_blank");
    },
    viewcode() {
      this.codeDialog = true;
    },
    closePopover() {
      document.body.click();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "@/assets/css/outsideProcard.css";
.engineerpath {
  position: absolute;
  right: 42px;
  top: 80px;
  cursor: pointer;
}

::v-deep .el-input__inner {
  border: none;
  box-shadow: none;
}
.viewpover {
  color: #7856fd;
  font-size: 14px;
  line-height: 32px;
  vertical-align: middle;
  margin-top: -4px;
  display: inline-block;
}
/* a {
  color: #42b983;
} */
</style>
