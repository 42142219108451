<template>
  <div class="outsidePro" style="background: #f5f6fa">
    <div
      class="engineerpath"
      v-if="skillCertState == 0"
      @click="pathengineer()"
    >
      <img src="../../assets/images/auth/engAuth_icon.png" alt="" />
    </div>
    <div class="prodetail_box">
      <p class="prodetail_tit" @click="downloadPDF()">接单流程</p>
      <div class="process_box">
        <p class="process_bg"></p>
        <ul>
          <li>
            <p><img src="../../assets/images/auth/process_1.png" alt="" /></p>
            <p>01查看项目</p>
            <p>查看项目要求，下载项目附件<br />了解项目结构</p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_2.png" alt="" /></p>
            <p>02获取项目</p>
            <p>查看<span @click="goorderNotice('1')">《项目须知》</span></p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_3.png" alt="" /></p>
            <p>03项目深化</p>
            <p>项目工程问题可通过项目答疑<br />进行实时反馈</p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_4.png" alt="" /></p>
            <p>04项目交付</p>
            <p>线上交付已完成的项目文件</p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_5.png" alt="" /></p>
            <p>05项目验收</p>
            <p>将项目问题修改后，重新上传<br />项目文件</p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_6.png" alt="" /></p>
            <p>06收益到账</p>
            <p>项目验收完成后，收益会在3个<br />工作日内到账，请注意查收</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="prodetail_timeline">
      <p class="prodetail_tit">项目动态</p>
      <ul class="timeline-wrapper">
        <li
          class="timeline-item"
          v-for="(item, index) in progressdata"
          :key="index"
          :class="{ timeline_active: index == 0 }"
        >
          <div class="timeline-date">
            <p style="padding-bottom: 10px">{{ item.date | getDateDiff }}</p>
            <p>{{ item.date }}</p>
          </div>
          <div class="timeline-box">
            <div class="out-circle" v-if="index == 0">
              <div class="in-circle"></div>
            </div>
            <div v-else class="circle"></div>

            <div class="long-line" v-if="progressdata.length > index + 1"></div>
          </div>
          <div
            class="timeline-content"
            v-for="(t, index2) in item.data"
            :key="index2"
          >
            <div class="timeline-title" :class="{ tit_active: index2 == 0 }">
              {{ t.typeStr }}
            </div>
            <div class="timeline-desc">
              <p class="htmlcon" v-html="t.content"></p>
            </div>
          </div>
        </li>
      </ul>
      <p class="viewmore pointer" v-if="page < pages" @click="moreShow">
        查看更多
      </p>
    </div>

    <div class="announce">
      <el-dialog
        custom-class="isAuthenticDialog"
        :visible.sync="dialogAnnounce"
        width="595px"
        :before-close="handleClose2"
        :close-on-click-modal="false"
      >
        <div class="isAuth_icon announce_icon"></div>
        <div class="isAuth_close anno_close" @click="handleClose2()"></div>
        <div class="announce_bg" style=""></div>

        <div class="announce_box">
          <p class="isAuth_tit announce_tit">平台公告</p>
          <div class="announce_txt">
            <p>亲爱的用户，您好:</p>
            <p style="text-indent: 30px">
              感谢您对我们平台的支持，根据试用期反馈，将在近期对平台进行改版升级，暂时停用发布项目功能，预计11月中旬重新上线发布项目功能。我们将尽力在更新过程中为您提供最好的服务。感谢您的理解、支持和持续关注！
            </p>
          </div>
          <div
            style="margin-top: 50px"
            class="authBut pointer"
            @click="handleClose2()"
          >
            知道了
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Global from "@/assets/js/global";
import streamSaver from "streamsaver";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
export default {
  components: {},
  name: "Home",
  data() {
    return {
      page: 1,
      limit: 3,
      progressdata: [],
      progresslength: 0,
      dialogAnnounce: false,
      isAnnounce: 0,
      pages: 0,
      skillCertState: window.sessionStorage.getItem("skillCertState"),
      timelineList: [
        {
          date: "2018-04-03",
          title: "支持自定义颜色",
          content:
            "平台发布新项目，23029-006&工业类-钢混框架，预计工作时长20h，预计收益2600元，请前往",
        },
        {
          date: "2018-04-03",
          title: "支持自定义颜色",
          content: "工业类-钢混框架 模型品控发现新的模型问题",
        },
        {
          date: "2018-04-03",
          title: "支持自定义颜色",
          content: "工业类-钢混框架 模型品控发现新的模型问题",
        },
      ],
    };
  },
  created() {
    this.getHomeProgress();
    this.isAnnounce = window.sessionStorage.getItem("userAnnounce");

    if (this.isAnnounce == 0) {
      console.log("222");
      this.dialogAnnounce = true;
    } else {
      this.dialogAnnounce = false;
    }
  },
  mounted() {
    window.btnClick = this.btnClick;
  },
  // watch: {
  //   $route(to, from) {
  //     console.log(to.name);
  //     //如果是组件A跳转到的组件B，将组件B刷新
  //     this.$router.go(0);
  //   },
  // },
  // //组件B跳转后将组件B的 keepAlive 值设置为false
  // beforeRouteLeave(to, from, next) {
  //   console.log(to.name);
  //   from.meta.keepAlive = false;
  //   next();
  // },
  methods: {
    downloadPDF() {
      const element = document.body; // 要转换成PDF的HTML元素
      html2canvas(element).then((canvas) => {
        const pdf = new jsPDF();
        const imgData = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("download.pdf"); // 生成PDF文件并下载
      });
    },
    goorderNotice(type) {
      const { href } = this.$router.resolve({
        path: `/orderNotice`,
        query: { noticeType: type },
      });

      window.open(href, "_blank");
    },
    getHomeProgress() {
      this.$http
        .get(
          "/Api/outsideProgress/getHomeProgress?page=" +
            this.page +
            "&limit=" +
            this.limit
        )
        .then((res) => {
          var progressdata = res.data.data;
          this.progresslength = res.data.count;
          this.progressdata = this.progressdata.concat(progressdata);

          console.log(this.progressdata);

          // this.progresstTaskid =
          //   this.progressdata[0].data[0].progresssData[0].projectTaskId;
          // this.progressid = this.progressdata[0].data[0].progresssData[0].id;
          this.pages = res.data.pages;
          // this.getprogressdata();
        });
    },
    handleClose2() {
      window.sessionStorage.setItem("userAnnounce", 1);
      this.dialogAnnounce = false;
      // window.sessionStorage.removeItem("showCredentialsFlag");
    },
    moreShow() {
      this.page++;
      this.getHomeProgress();
    },
    btnClick(url) {
      this.$router.push(url);
    },
    pathengineer() {
      this.$router.push("/engineerAuth");
    },
  },
  filters: {
    getDateDiff: function (value) {
      console.log(value);
      let dateTimeStamp = new Date(value).setHours(0, 0, 0, 0);
      let now = new Date().setHours(0, 0, 0, 0);
      let result;
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let diffValue = now - dateTimeStamp;
      console.log(diffValue);
      if (diffValue < 0) {
        return;
      }
      let dayC = diffValue / day;
      if (parseInt(dayC) == 1) {
        result = "昨天";
      } else if (parseInt(dayC) == 0) {
        result = "今天";
      } else {
        result = "";
      }
      return result;
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/outsidePro.css";

.prodetail_box {
  width: 1120px;
  height: 230px;
  background: #ffffff;
  margin: 20px auto;
}

.process_tit {
  font-weight: bold;
  font-size: 16px;
  color: #7856fd;
  line-height: 30px;
  margin-left: 40px;
  margin-top: 20px;
}
.prodetail_box .process_box {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  position: relative;
}

.prodetail_box .process_bg {
  width: 924px;
  height: 3px;
  background: #eaeeff;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  top: 36px;
  left: calc((100% - 924px) / 2);
}
.process_box ul {
  width: 100%;
  position: absolute;
  z-index: 2;
}
.process_box ul li {
  width: 16.5%;
  float: left;
  text-align: center;
}
.process_box ul li p {
  text-align: center;
}
.process_box ul li p span {
  color: #7856fd;
  cursor: pointer;
}
.prodetail_box .process_box ul li p:first-child {
  margin-top: 20px;
}
.prodetail_box .process_box ul li p:nth-child(2) {
  font-size: 14px;
  color: #333333;
  margin-top: 24px;
}
.prodetail_box .process_box ul li p:nth-child(3) {
  width: 158px;
  font-size: 10px;
  color: #7d8599;
  line-height: 18px;
  margin: 0 auto;
  margin-top: 6px;
}
.prodetail_box .process_box ul li img {
  width: 36px;
  height: 36px;
}
.engAuth_nodata {
  padding-top: 300px;
}
.engAuth_nodata p {
  text-align: center;
}
.engAuth_nodata p:nth-child(2) {
  font-size: 14px;
  color: #333333;
  line-height: 30px;
}
.engAuth_nodata p:nth-child(3) {
  font-size: 12px;
  color: #7d8599;
}

ul.timeline-wrapper {
  list-style: none;
  margin: 0;
  padding: 40px 40px;
}

/* 时间线 */
.timeline-item {
  position: relative;
  padding-bottom: 10px;
}
.long-line {
  width: 1px;
  height: calc(100% - 6px);
  background: #aaaebb;
  margin-left: 5px;
}
.timeline_active .long-line {
  height: calc(100% - 12px);
}

.timeline_active .tit_active {
  color: #967bfd;
}
.timeline-box {
  text-align: center;
  position: absolute;
  left: 102px;
  height: 100%;
}
.circle {
  width: 6px;
  height: 6px;
  background: #aaaebb;
  border-radius: 50%;
  margin-left: 3px;
}
.out-circle {
  width: 12px;
  height: 12px;
  background: #d4c9fe;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.in-circle {
  width: 8px;
  height: 8px;
  margin: 0 auto;
  background: #7856fd;
  border-radius: 50%;
}

.timeline-content {
  box-sizing: border-box;
  margin-left: 130px;
  padding: 0 0 0 20px;
  text-align: left;
  margin-bottom: 24px;
}
.timeline-title {
  font-size: 12px;
  word-break: break-all;
  margin-bottom: 10px;
  color: #333;
  font-weight: 550;
  /*display: inline;*/
}

.timeline-date {
  font-size: 12px;
  color: #333;
  font-weight: 550;
  margin-bottom: 16px;
  position: absolute;

  width: 80px;
}
.timeline-date p {
  text-align: right;
}
.timeline-desc {
  font-size: 12px;
  color: #878ea0;
}
.timeline-desc span a {
  text-decoration: #7856fd !important;
  cursor: pointer;
}
/* .timeline-item:last-of-type .timeline-content {
  margin-bottom: 0;
} */
.prodetail_timeline {
  background: #fff;
  padding: 0px 20px 20px 0px;
}
.prodetail_tit {
  font-size: 12px;
  color: #333333;
  line-height: 24px;
  font-weight: 550;
  padding: 30px 0px 0px 30px;
}
.engineerpath {
  position: absolute;
  right: 42px;
  top: 80px;
  cursor: pointer;
}

.announce_bg {
  position: absolute;
  z-index: 1;
  width: 595px;
  /* height: 358px; */
  background: #ffffff;
  border-radius: 8px;
  top: 50px;
  background: url("../../assets/images/outsidepro/announce_bg.png");
}

.isAuth_icon {
  width: 234px;
  height: 168px;
  position: absolute;
  top: 20px;
  z-index: 2;
  background: url("../../assets/images/outsidepro/auth_icon.png");
  /* left: 82px; */
}

.announce_icon {
  width: 234px;
  height: 168px;
  position: absolute;
  top: 30px;
  z-index: 4;
  background: url("../../assets/images/outsidepro/anno_icon.png");
  left: 360px !important;
}

.isAuth_close {
  width: 20px;
  height: 50px;
  position: absolute;
  top: 0px;
  right: 22px;
  z-index: 2;
  background: url("../../assets/images/outsidepro/auth_close.png");
}

.anno_close {
  top: 10px;
  right: 20px;
}

.isAuth_box {
  position: absolute;
  z-index: 3;
  width: 400px;
  border-radius: 8px;
  top: 160px;
}

.announce_box {
  position: absolute;
  z-index: 3;
  width: 595px;
  border-radius: 8px;
  left: 0px;
  top: 60px;
  background: url("../../assets/images/outsidepro/announce_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-bottom: 32px;
}

.isAuth_tit {
  font-size: 26px;
  color: #000000;
  line-height: 30px;
  text-align: center;
}

.isAuth_txt {
  width: 333px;
  font-size: 16px;
  color: #636977;
  line-height: 28px;
  margin: 0 auto;
  margin-top: 20px;
}

.authBut {
  width: 240px;
  height: 42px;
  line-height: 42px;
  background: #7856fd;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  margin-top: 40px;
}

.nexttime {
  font-size: 14px;
  color: #7856fd;
  line-height: 30px;
  text-align: center;
  margin-top: 4px;
}

.announce_tit {
  margin-top: 52px;
}

.announce_txt {
  width: 480px;
  margin: 0 auto;
  font-size: 16px;
  color: #636977;
  line-height: 28px;
  margin-top: 20px;
  min-height: 120px;
}
</style>
 